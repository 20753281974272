import dayjs from 'dayjs';
import { post } from '../request';
import { isEmpty } from 'lodash';
import { userStore } from '../../store/UserStore';
import { PUBLISHED_STATUS } from '../../../../common/def/tables/article.mjs';

const ARTICLE_LIST_API = '/api/article/list';

export async function getArticleList(query) {
    const res = await post({ url: ARTICLE_LIST_API, body: query });
    console.log(res);
    if (res && Array.isArray(res.results)) {
        handleArticleList(res.results);
        return res;
    }
    return {};
}
export function getIndexArticleList({ pageNumber, pageSize }) {
    return getArticleList({
        pageNumber,
        pageSize,
        status: PUBLISHED_STATUS,
    });
}
export function getMyArticleList({ pageNumber, pageSize }) {
    return getArticleList({
        pageNumber,
        pageSize,
        author: userStore.id,
    });
}
function handleArticleList(list) {
    return list.forEach(article => {
        article.create_time_formatted = dayjs(article.create_time).format('YYYY-MM-DD HH:mm:ss');
        article.update_time_formatted = dayjs(article.update_time).format('YYYY-MM-DD HH:mm:ss');
    });
}
export async function getArticleInfo(id) {
    const res = await post({ url: '/api/article/info', body: { id } });
    if (isEmpty(res)) {
        return {};
    }
    if (res.tags && typeof res.tags === 'string') {
        res.tags = JSON.parse(res.tags);
    }
    return res;
}
