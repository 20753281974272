import { max } from 'lodash';
import { ARTICLE_AUTHOR, SYSTEM_ADMIN } from '../privilege/user.mjs';
import { createTableDef, LEFT_JOIN } from './common.mjs';
import { USER_TABLE_NAME } from './user.mjs';

export const ARTICLE_TABLE_NAME = 'article';
export const DRAFT_STATUS = 'draft';
export const ARTICLE_STATUS = 'status';
export const PUBLISHED_STATUS = 'published';
export const AUDITING_STATUS = 'auditing';
export const REJECTED_STATUS = 'rejected';
export const articleTable = {
    tableName: ARTICLE_TABLE_NAME,
    tableDisplayName: '文章',
    joinTables: [
        {
            joinSide: LEFT_JOIN,
            tableName: USER_TABLE_NAME,
            on: `${USER_TABLE_NAME}.id = ${ARTICLE_TABLE_NAME}.author`,
        },
    ],
    refColumns: [
        {
            alias: 'author_name',
            refTableName: USER_TABLE_NAME,
            refColumn: 'nickname',
        },
    ],
    columns: [
        {
            key: 'title',
            dataType: 'TEXT',
            notNull: true,
            label: '标题',
        },
        {
            key: 'author',
            dataType: 'TEXT',
            notNull: true,
            label: '作者ID',
        },
        {
            key: ARTICLE_STATUS,
            dataType: 'TEXT',
            notNull: true,
            label: '状态',
            inputType: 'select',
            options: [
                {
                    value: DRAFT_STATUS,
                    label: '草稿',
                    visible: [ARTICLE_AUTHOR, SYSTEM_ADMIN],
                },
                {
                    value: PUBLISHED_STATUS,
                    label: '已发布',
                    visible: [SYSTEM_ADMIN],
                },
                {
                    value: AUDITING_STATUS,
                    label: '审核中',
                    visible: [ARTICLE_AUTHOR, SYSTEM_ADMIN],
                },
                {
                    value: REJECTED_STATUS,
                    label: '驳回',
                    visible: [SYSTEM_ADMIN],
                },
            ],
        },
        {
            key: 'summary',
            dataType: 'TEXT',
            label: '摘要',
        },
        {
            key: 'content',
            dataType: 'TEXT',
            notNull: true,
            label: '内容',
            maxLength: 100000,
        },
        {
            key: 'tags',
            dataType: 'TEXT',
            label: '标签',
            inputType: 'tags',
        },
    ],
};
export const ArticleTableDef = createTableDef(articleTable);
