import React from 'react';
import { renderApp, setTitle } from '../lib/common.jsx';
import '../../style/index.less';
import { ArticleList } from '../components/article/ArticleList.jsx';
import { getIndexArticleList } from '../lib/article/article.jsx';

setTitle('首页');

function IndexPage() {
    return (
        <div id='index-page'>
            <ArticleList getArticleList={getIndexArticleList} />
        </div>
    );
}

renderApp(IndexPage);
